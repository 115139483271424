<template>
  <v-row wrap style="margin:0">
    <v-col v-if="selection !== 'manual'" cols="12">
      <v-autocomplete v-model="selection" :search-input.sync="search" :items="results" :label="label" item-value="value" item-text="search" outlined @change="(value) => updateField('id', value)" :filter="() => true" :rules="rules">
        <template v-slot:prepend-inner>
          <v-dialog v-model="infoWindow" width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="primary">fas fa-question-circle</v-icon>
            </template>
            <v-card>
              <v-card-title>Church Search</v-card-title>
              <v-card-text>Search for the church by entering part of the church name, city, and/or state. The list will load the first 20 matches, so if the church is not in the list then try adding more information. If the church is still not found, click the "No match found" option to manually enter the church's name, city, and state.</v-card-text>
              <v-card-actions>
                <v-btn text @click="infoWindow = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.city + (item.city && item.state ? ', ' + item.state : '') + ((item.city || item.state) && item.nation ? ', ' + item.nation : (item.nation || '')) }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <template v-slot:no-data>
          <v-list-item @click="selection = 'manual'">
            <v-list-item-title>No match found; click here to manually enter information</v-list-item-title>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col v-if="selection === 'manual'" cols="12" md="4">
      <v-text-field label="Church Name" v-model="churchName" outlined @change="(value) => updateField('name', value)" :rules="rules"></v-text-field>
    </v-col>
    <v-col v-if="selection === 'manual'" cols="12" md="4">
      <v-text-field label="Church City" v-model="churchCity" outlined @change="(value) => updateField('city', value)" :rules="rules"></v-text-field>
    </v-col>
    <v-col v-if="selection === 'manual'" cols="12" md="4">
      <v-select label="Church State" v-model="churchState" :items="states" outlined @change="(value) => updateField('state', value)" :rules="rules"></v-select>
    </v-col>
  </v-row>
</template>
<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: [Object, String, Number],
      default: () => {
        return { id: '' }
      }
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root, emit }) {
    const selection = ref('')
    const search = ref('')
    const results = ref([])
    const rules = computed(() => {
      if (props.required) return [v => !!v || 'Required']
      else return []
    })

    watch(search, () => {
      if (search.value != null && search.value !== '') {
        let wcSearch = '%' + search.value.replace(/ /ig, '%') + '%'
        root.$feathers.service('warehouse/churches').find({ query: { search: { $like: wcSearch }, $limit: 20, $sort: { search: 1 } } }).then(({ data }) => {
          results.value = data
        })
      }
    })

    const churchName = ref('')
    const churchCity = ref('')
    const churchState = ref('')
    const states = ref([])

    onMounted(() => {
      root.$feathers.service('forms/select-options').find({ query: { 'OptGroup': 'StateProvinceTerritory' } }).then((data) => {
        states.value = data
      })
    })

    watch(() => props.value, () => {
      if (props.value.id != null && props.value.id !== '') {
        if (props.value.id === 'manual') {
          selection.value = 'manual'
        } else {
          root.$feathers.service('warehouse/churches').get(props.value.id).then((data) => {
            results.value = [ data ]
            selection.value = parseInt(props.value.id)
          })
        }
      }
      if (props.value.name != null && props.value.name !== '') {
        churchName.value = props.value.name
      }
      if (props.value.city != null && props.value.city !== '') {
        churchCity.value = props.value.city
      }
      if (props.value.state != null && props.value.state !== '') {
        churchState.value = props.value.state
      }
    })

    const infoWindow = ref(false)

    function updateField (field, value) {
      let obj = {
        id: selection.value
      }
      if (selection.value === 'manual') {
        obj.name = churchName.value
        obj.city = churchCity.value
        obj.state = churchState.value
      }
      emit('input', obj)
    }

    return {
      selection,
      search,
      results,
      rules,
      churchName,
      churchCity,
      churchState,
      states,
      infoWindow,
      updateField
    }
  }
}
</script>
